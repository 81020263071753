(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['ComboBoxInputSkin'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_selector-wrapper"
      ],
      {},
      [
        "select",
        "collection",
        [],
        {}
      ],
      [
        "div",
        "arrow",
        [],
        {},
        [
          "div",
          null,
          [
            "_svg_container"
          ],
          {},
          [
            "svg",
            "icon",
            [],
            {
              "data-name": "Layer 1",
              "xmlns": "http://www.w3.org/2000/svg",
              "viewBox": "0 0 9.2828 4.89817"
            },
            [
              "defs",
              null,
              [],
              {}
            ],
            [
              "title",
              null,
              [],
              {},
              "arrow&amp;v"
            ],
            [
              "path",
              null,
              [
                "_cls-1"
              ],
              {
                "d": "M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z"
              }
            ]
          ]
        ]
      ]
    ]
  ],
  "params": {
    "brwe": "BORDER_SIZES",
    "brde": "BORDER_COLOR_ALPHA",
    "bge": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "bg": "BG_COLOR_ALPHA",
    "txt": "TEXT_COLOR",
    "brw": "BORDER_SIZES",
    "brd": "BORDER_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "txt_placeholder": "TEXT_COLOR",
    "brwh": "BORDER_SIZES",
    "brdh": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "brwf": "BORDER_SIZES",
    "brdf": "BORDER_COLOR_ALPHA",
    "bgf": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA",
    "fntlbl": "FONT",
    "txtlbl": "TEXT_COLOR",
    "txtlblrq": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brwe": "2px",
    "brde": "#F9F9F9",
    "bge": "color_8",
    "rd": "5px",
    "shd": "0 0 0 rgba(0, 0, 0, 0)",
    "fnt": "font_7",
    "bg": "color_8",
    "txt": "#888888",
    "brw": "2px",
    "brd": "#F9F9F9",
    "txt2": "color_15",
    "txt_placeholder": "#888888",
    "brwh": "2px",
    "brdh": "#F9F9F9",
    "bgh": "color_8",
    "brwf": "2px",
    "brdf": "#F9F9F9",
    "bgf": "color_8",
    "brdd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "bgd": "#CCCCCC",
    "arrowColor": "color_12",
    "fntlbl": "font_8",
    "txtlbl": "color_15",
    "txtlblrq": "transparent"
  },
  "css": {
    "%_with-validation-indication select:not([data-preview~=\"focus\"]):invalid": "[brwe]  border-style:solid;border-color:[brde];background-color:[bge];",
    "%": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "% select": "[rd]  -webkit-appearance:none;-moz-appearance:none;[shd]  [fnt]  background-color:[bg];color:[txt];[brw]  border-style:solid;border-color:[brd];margin:0;padding:0 45px;cursor:pointer;position:relative;max-width:100%;min-width:100%;min-height:10px;height:100%;text-overflow:ellipsis;white-space:nowrap;display:block;",
    "% select option": "text-overflow:ellipsis;white-space:nowrap;overflow:hidden;color:#44474D;background-color:#FFFFFF;",
    "% select%_placeholder-style": "color:[txt2];",
    "% select%_extended-placeholder-style": "color:[txt_placeholder];",
    "% select:-moz-focusring": "color:transparent;text-shadow:0 0 0 #000;",
    "% select::-ms-expand": "display:none;",
    "% select:focus::-ms-value": "background:transparent;",
    "% select:not(%_mobileCollection):hover,% select:not(%_mobileCollection)[data-preview~=\"hover\"]": "[brwh]  border-style:solid;border-color:[brdh];background-color:[bgh];",
    "% select:not(%_mobileCollection):focus,% select:not(%_mobileCollection)[data-preview~=\"focus\"]": "[brwf]  border-style:solid;border-color:[brdf];background-color:[bgf];",
    "% select:not(%_mobileCollection)[data-error=\"true\"],% select:not(%_mobileCollection)[data-preview~=\"error\"]": "[brwe]  border-style:solid;border-color:[brde];background-color:[bge];",
    "% select:not(%_mobileCollection):disabled,% select:not(%_mobileCollection)[data-disabled=\"true\"],% select:not(%_mobileCollection)[data-preview~=\"disabled\"]": "border-width:2px;border-color:[brdd];color:[txtd];background-color:[bgd];",
    "% select:not(%_mobileCollection):disabled + %arrow,% select:not(%_mobileCollection)[data-disabled=\"true\"] + %arrow,% select:not(%_mobileCollection)[data-preview~=\"disabled\"] + %arrow": "border-width:2px;border-color:[brdd];color:[txtd];border:none;",
    "%arrow": "position:absolute;pointer-events:none;top:0;bottom:0;box-sizing:border-box;padding-left:20px;padding-right:20px;height:inherit;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%arrow %_svg_container": "width:12px;",
    "%arrow %_svg_container svg": "height:100%;fill:[arrowColor];",
    "%_left-direction": "text-align-last:left;",
    "%_left-direction %arrow,%_left-direction select:hover + %arrow,%_left-direction select[data-preview~=\"hover\"] + %arrow,%_left-direction select:focus + %arrow,%_left-direction select[data-preview~=\"focus\"] + %arrow,%_left-direction[data-preview~=\"focus\"] %arrow,%_left-direction select[data-error=\"true\"] + %arrow,%_left-direction select[data-preview~=\"error\"] + %arrow": "border-left:0;",
    "%_left-direction %arrow": "right:0;",
    "%_right-direction": "text-align-last:right;direction:rtl;",
    "%_right-direction %arrow,%_right-direction select:hover + %arrow,%_right-direction select[data-preview~=\"hover\"] + %arrow,%_right-direction select:focus + %arrow,%_right-direction select[data-preview~=\"focus\"] + %arrow,%_right-direction[data-preview~=\"focus\"] %arrow,%_right-direction select[data-error=\"true\"] + %arrow,%_right-direction select[data-preview~=\"error\"] + %arrow": "border-right:0;",
    "%_right-direction %arrow": "left:0;",
    "%_center-direction": "text-align-last:left;text-align-last:center;",
    "%_center-direction %arrow,%_center-direction select:hover + %arrow,%_center-direction select[data-preview~=\"hover\"] + %arrow,%_center-direction select:focus + %arrow,%_center-direction select[data-preview~=\"focus\"] + %arrow,%_center-direction[data-preview~=\"focus\"] %arrow,%_center-direction select[data-error=\"true\"] + %arrow,%_center-direction select[data-preview~=\"error\"] + %arrow": "border-left:0;",
    "%_center-direction %arrow": "right:0;",
    "%_mobileMenuContainer": "border:0;",
    "%_mobileMenuContainer %arrow %_svg_container %icon": "fill:[txt];",
    "%label": "[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;",
    "%_required %label::after": "content:\" *\";color:[txtlblrq];",
    "%_selector-wrapper": "-webkit-box-flex:1;-webkit-flex:1;flex:1;position:relative;"
  }
}
 skins['MembersComboBoxInputSkin'] = {
  "react": [
    [
      "label",
      "label",
      [],
      {}
    ],
    [
      "div",
      null,
      [
        "_selector-wrapper"
      ],
      {},
      [
        "select",
        "collection",
        [],
        {}
      ],
      [
        "div",
        "arrow",
        [],
        {},
        [
          "div",
          null,
          [
            "_svg_container"
          ],
          {},
          [
            "svg",
            "icon",
            [],
            {
              "data-name": "Layer 1",
              "xmlns": "http://www.w3.org/2000/svg",
              "viewBox": "0 0 9.2828 4.89817"
            },
            [
              "defs",
              null,
              [],
              {}
            ],
            [
              "title",
              null,
              [],
              {},
              "arrow&amp;v"
            ],
            [
              "path",
              null,
              [
                "_cls-1"
              ],
              {
                "d": "M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z"
              }
            ]
          ]
        ]
      ]
    ]
  ],
  "params": {
    "brwe": "BORDER_SIZES",
    "brde": "BORDER_COLOR_ALPHA",
    "bge": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "bg": "BG_COLOR_ALPHA",
    "txt": "TEXT_COLOR",
    "txt2": "TEXT_COLOR",
    "txt_placeholder": "TEXT_COLOR",
    "brwh": "BORDER_SIZES",
    "brdh": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "brwf": "BORDER_SIZES",
    "brdf": "BORDER_COLOR_ALPHA",
    "bgf": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA",
    "fntlbl": "FONT",
    "txtlbl": "TEXT_COLOR",
    "txtlblrq": "COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brwe": "2px",
    "brde": "#F9F9F9",
    "bge": "color_8",
    "rd": "5px",
    "shd": "0 0 0 rgba(0, 0, 0, 0)",
    "fnt": "font_7",
    "bg": "color_8",
    "txt": "#888888",
    "txt2": "color_15",
    "txt_placeholder": "#888888",
    "brwh": "2px",
    "brdh": "#F9F9F9",
    "bgh": "color_8",
    "brwf": "2px",
    "brdf": "#F9F9F9",
    "bgf": "color_8",
    "brdd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "bgd": "#CCCCCC",
    "arrowColor": "color_12",
    "fntlbl": "font_8",
    "txtlbl": "color_15",
    "txtlblrq": "transparent"
  },
  "css": {
    "%_with-validation-indication select:not([data-preview~=\"focus\"]):invalid": "[brwe]  border-style:solid;border-color:[brde];background-color:[bge];",
    "%": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "% select": "[rd]  -webkit-appearance:none;-moz-appearance:none;[shd]  [fnt]  background-color:[bg];color:[txt];border-style:solid;border-color:rgba(0, 0, 0, 0.2);border-width:1px;border-left-width:0px;border-right-width:0px;margin:0;padding:0 45px;cursor:pointer;position:relative;max-width:100%;min-width:100%;min-height:10px;height:100%;text-overflow:ellipsis;white-space:nowrap;display:block;",
    "% select option": "text-overflow:ellipsis;white-space:nowrap;overflow:hidden;color:#44474D;background-color:#FFFFFF;",
    "% select%_placeholder-style": "color:[txt2];",
    "% select%_extended-placeholder-style": "color:[txt_placeholder];",
    "% select:-moz-focusring": "color:transparent;text-shadow:0 0 0 #000;",
    "% select::-ms-expand": "display:none;",
    "% select:focus::-ms-value": "background:transparent;",
    "% select:not(%_mobileCollection):hover,% select:not(%_mobileCollection)[data-preview~=\"hover\"]": "[brwh]  border-style:solid;border-color:[brdh];background-color:[bgh];",
    "% select:not(%_mobileCollection):focus,% select:not(%_mobileCollection)[data-preview~=\"focus\"]": "[brwf]  border-style:solid;border-color:[brdf];background-color:[bgf];",
    "% select:not(%_mobileCollection)[data-error=\"true\"],% select:not(%_mobileCollection)[data-preview~=\"error\"]": "[brwe]  border-style:solid;border-color:[brde];background-color:[bge];",
    "% select:not(%_mobileCollection):disabled,% select:not(%_mobileCollection)[data-disabled=\"true\"],% select:not(%_mobileCollection)[data-preview~=\"disabled\"]": "border-width:2px;border-color:[brdd];color:[txtd];background-color:[bgd];",
    "% select:not(%_mobileCollection):disabled + %arrow,% select:not(%_mobileCollection)[data-disabled=\"true\"] + %arrow,% select:not(%_mobileCollection)[data-preview~=\"disabled\"] + %arrow": "border-width:2px;border-color:[brdd];color:[txtd];border:none;",
    "%arrow": "position:absolute;pointer-events:none;top:0;bottom:0;box-sizing:border-box;padding-left:20px;padding-right:20px;height:inherit;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;border-style:solid;border-color:rgba(0, 0, 0, 0.2);border-left-width:1px;",
    "%arrow %_svg_container": "width:12px;",
    "%arrow %_svg_container svg": "height:100%;fill:[arrowColor];",
    "%_left-direction": "text-align-last:left;",
    "%_left-direction %arrow,%_left-direction select:hover + %arrow,%_left-direction select[data-preview~=\"hover\"] + %arrow,%_left-direction select:focus + %arrow,%_left-direction select[data-preview~=\"focus\"] + %arrow,%_left-direction[data-preview~=\"focus\"] %arrow,%_left-direction select[data-error=\"true\"] + %arrow,%_left-direction select[data-preview~=\"error\"] + %arrow": "border-right:0;",
    "%_left-direction %arrow": "right:0;border-left-width:1px;",
    "%_right-direction": "text-align-last:right;direction:rtl;",
    "%_right-direction %arrow,%_right-direction select:hover + %arrow,%_right-direction select[data-preview~=\"hover\"] + %arrow,%_right-direction select:focus + %arrow,%_right-direction select[data-preview~=\"focus\"] + %arrow,%_right-direction[data-preview~=\"focus\"] %arrow,%_right-direction select[data-error=\"true\"] + %arrow,%_right-direction select[data-preview~=\"error\"] + %arrow": "border-left:0;",
    "%_right-direction %arrow": "left:0;border-right-width:1px;",
    "%_center-direction": "text-align-last:left;text-align-last:center;",
    "%_center-direction %arrow,%_center-direction select:hover + %arrow,%_center-direction select[data-preview~=\"hover\"] + %arrow,%_center-direction select:focus + %arrow,%_center-direction select[data-preview~=\"focus\"] + %arrow,%_center-direction[data-preview~=\"focus\"] %arrow,%_center-direction select[data-error=\"true\"] + %arrow,%_center-direction select[data-preview~=\"error\"] + %arrow": "border-right:0;",
    "%_center-direction %arrow": "right:0;border-left-width:1px;",
    "%_mobileMenuContainer": "border:0;",
    "%_mobileMenuContainer %arrow %_svg_container %icon": "fill:[txt];",
    "%label": "[fntlbl]  color:[txtlbl];word-break:break-word;display:inline-block;line-height:1;",
    "%_required %label::after": "content:\" *\";color:[txtlblrq];",
    "%_selector-wrapper": "-webkit-box-flex:1;-webkit-flex:1;flex:1;position:relative;"
  }
}
 skins['ResponsiveComboBoxInputSkin'] = {
  "react": [
    [
      "div",
      null,
      [
        "_selector-wrapper"
      ],
      {},
      [
        "select",
        "collection",
        [],
        {}
      ],
      [
        "div",
        "arrow",
        [],
        {},
        [
          "div",
          null,
          [
            "_svg_container"
          ],
          {},
          [
            "svg",
            "icon",
            [],
            {
              "data-name": "Layer 1",
              "xmlns": "http://www.w3.org/2000/svg",
              "viewBox": "0 0 9.2828 4.89817"
            },
            [
              "defs",
              null,
              [],
              {}
            ],
            [
              "title",
              null,
              [],
              {},
              "arrow&amp;v"
            ],
            [
              "path",
              null,
              [
                "_cls-1"
              ],
              {
                "d": "M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z"
              }
            ]
          ]
        ]
      ]
    ]
  ],
  "params": {
    "brwe": "BORDER_SIZES",
    "brde": "BORDER_COLOR_ALPHA",
    "bge": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "bg": "BG_COLOR_ALPHA",
    "txt": "TEXT_COLOR",
    "brw": "BORDER_SIZES",
    "brd": "BORDER_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "txt_placeholder": "TEXT_COLOR",
    "brwh": "BORDER_SIZES",
    "brdh": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "brwf": "BORDER_SIZES",
    "brdf": "BORDER_COLOR_ALPHA",
    "bgf": "BG_COLOR_ALPHA",
    "brdd": "BORDER_COLOR_ALPHA",
    "txtd": "TEXT_COLOR",
    "bgd": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brwe": "2px",
    "brde": "#F9F9F9",
    "bge": "color_8",
    "rd": "5px",
    "shd": "0 0 0 rgba(0, 0, 0, 0)",
    "fnt": "font_7",
    "bg": "color_8",
    "txt": "#888888",
    "brw": "2px",
    "brd": "#F9F9F9",
    "txt2": "color_15",
    "txt_placeholder": "#888888",
    "brwh": "2px",
    "brdh": "#F9F9F9",
    "bgh": "color_8",
    "brwf": "2px",
    "brdf": "#F9F9F9",
    "bgf": "color_8",
    "brdd": "#CCCCCC",
    "txtd": "#FFFFFF",
    "bgd": "#CCCCCC",
    "arrowColor": "color_12"
  },
  "css": {
    "%_with-validation-indication select:not([data-preview~=\"focus\"]):invalid": "[brwe]  border-style:solid;border-color:[brde];background-color:[bge];",
    "% select": "[rd]  -webkit-appearance:none;-moz-appearance:none;[shd]  [fnt]  background-color:[bg];color:[txt];[brw]  border-style:solid;border-color:[brd];margin:0;padding:0 45px;cursor:pointer;position:relative;width:100%;text-overflow:ellipsis;white-space:nowrap;display:block;grid-area:1/1/2/2;",
    "% select option": "text-overflow:ellipsis;white-space:nowrap;overflow:hidden;",
    "% select%_placeholder-style": "color:[txt2];",
    "% select%_extended-placeholder-style": "color:[txt_placeholder];",
    "% select:-moz-focusring": "color:transparent;text-shadow:0 0 0 #000;",
    "% select::-ms-expand": "display:none;",
    "% select:focus::-ms-value": "background:transparent;",
    "% select:not(%_mobileCollection):hover,% select:not(%_mobileCollection)[data-preview~=\"hover\"]": "[brwh]  border-style:solid;border-color:[brdh];background-color:[bgh];",
    "% select:not(%_mobileCollection):focus,% select:not(%_mobileCollection)[data-preview~=\"focus\"]": "[brwf]  border-style:solid;border-color:[brdf];background-color:[bgf];",
    "% select:not(%_mobileCollection)[data-error=\"true\"],% select:not(%_mobileCollection)[data-preview~=\"error\"]": "[brwe]  border-style:solid;border-color:[brde];background-color:[bge];",
    "% select:not(%_mobileCollection):disabled,% select:not(%_mobileCollection)[data-disabled=\"true\"],% select:not(%_mobileCollection)[data-preview~=\"disabled\"]": "border-width:2px;border-color:[brdd];color:[txtd];background-color:[bgd];",
    "% select:not(%_mobileCollection):disabled + %arrow,% select:not(%_mobileCollection)[data-disabled=\"true\"] + %arrow,% select:not(%_mobileCollection)[data-preview~=\"disabled\"] + %arrow": "border-width:2px;border-color:[brdd];color:[txtd];border:none;",
    "%arrow": "position:relative;pointer-events:none;box-sizing:border-box;padding-left:20px;padding-right:20px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;grid-area:1/1/2/2;justify-self:end;",
    "%arrow %_svg_container": "width:12px;",
    "%arrow %_svg_container svg": "height:100%;fill:[arrowColor];",
    "%_left-direction": "text-align-last:left;",
    "%_left-direction %arrow,%_left-direction select:hover + %arrow,%_left-direction select[data-preview~=\"hover\"] + %arrow,%_left-direction select:focus + %arrow,%_left-direction select[data-preview~=\"focus\"] + %arrow,%_left-direction[data-preview~=\"focus\"] %arrow,%_left-direction select[data-error=\"true\"] + %arrow,%_left-direction select[data-preview~=\"error\"] + %arrow": "border-left:0;",
    "%_left-direction %arrow": "right:0;",
    "%_right-direction": "text-align-last:right;",
    "%_right-direction %arrow,%_right-direction select:hover + %arrow,%_right-direction select[data-preview~=\"hover\"] + %arrow,%_right-direction select:focus + %arrow,%_right-direction select[data-preview~=\"focus\"] + %arrow,%_right-direction[data-preview~=\"focus\"] %arrow,%_right-direction select[data-error=\"true\"] + %arrow,%_right-direction select[data-preview~=\"error\"] + %arrow": "border-right:0;",
    "%_right-direction %arrow": "left:0;justify-self:start;",
    "%_center-direction": "text-align-last:left;text-align-last:center;",
    "%_center-direction %arrow,%_center-direction select:hover + %arrow,%_center-direction select[data-preview~=\"hover\"] + %arrow,%_center-direction select:focus + %arrow,%_center-direction select[data-preview~=\"focus\"] + %arrow,%_center-direction[data-preview~=\"focus\"] %arrow,%_center-direction select[data-error=\"true\"] + %arrow,%_center-direction select[data-preview~=\"error\"] + %arrow": "border-left:0;",
    "%_center-direction %arrow": "right:0;",
    "%_mobileMenuContainer": "border:0;",
    "%_mobileMenuContainer %arrow %_svg_container %icon": "fill:[txt];",
    "%_selector-wrapper": "position:absolute;top:0;right:0;bottom:0;left:0;display:grid;grid-template-columns:1fr;grid-template-rows:1fr;"
  }
}
 skins['wysiwyg.viewer.skins.appinputs.AppsComboBoxInputSkin'] = {
  "react": [
    [
      "select",
      "collection",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ]
  ],
  "params": {
    "txt": "TEXT_COLOR",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "bg2": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "txt": "#333",
    "brw": "2px",
    "brd": "#1ab1ab",
    "rd": "5px",
    "bg": "#fff",
    "bg2": "#aaa"
  },
  "css": {
    "%": "position:relative;display:inline-block;padding:0;margin:0;",
    "% select": "color:[txt];border:solid [brw] [brd];padding:5px 40px 5px 5px;[rd]  box-sizing:border-box !important;-webkit-appearance:none;-moz-appearance:none;background-color:[bg];position:relative;cursor:pointer;",
    "% option": "border:solid [brw] [brd];background-color:[bg];padding:5px;",
    "%:after": "content:\"?\";position:absolute;top:2px;bottom:2px;right:0;width:35px;pointer-events:none;[rd]  box-sizing:border-box !important;background-color:[bg2];border:[brw] solid [brd];color:#fff;text-align:center;font-size:10px;line-height:30px;text-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6);cursor:pointer !important;border-bottom-left-radius:0;border-top-left-radius:0;",
    "%[data-state~=\"invalid\"] select": "border-color:#d00;color:#d00;",
    "%[data-state~=\"invalid\"]:after": "content:\"?\";position:absolute;top:2px;bottom:2px;right:0;width:35px;pointer-events:none;[rd]  box-sizing:border-box !important;background-color:[bg2];border:#d00 solid [brd];color:#d00;text-align:center;font-size:10px;line-height:30px;text-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6);cursor:pointer !important;border-bottom-left-radius:0;border-top-left-radius:0;",
    "%[data-state~=\"invalid\"]:hover + p": "opacity:1;color:#d00;",
    "% p": "[rd]  opacity:0;display:inline-block;position:absolute;left:0;bottom:100%;width:140px;padding:10px;font-size:13px;line-height:16px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);",
    "% p:after": "content:\"?\";position:absolute;bottom:-12px;left:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);[rd]"
  }
}
 skins['wysiwyg.viewer.skins.appinputs.AppsComboBoxInputSkinNoValidation'] = {
  "react": [
    [
      "select",
      "collection",
      [],
      {}
    ]
  ],
  "params": {
    "txt": "TEXT_COLOR",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "bg2": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "txt": "#333",
    "brw": "2px",
    "brd": "#1ab1ab",
    "rd": "5px",
    "bg": "#fff",
    "bg2": "#aaa"
  },
  "css": {
    "%": "position:relative;display:inline-block;padding:0;margin:0;",
    "% select": "color:[txt];border:solid [brw] [brd];padding:5px 40px 5px 5px;[rd]  box-sizing:border-box !important;-webkit-appearance:none;-moz-appearance:none;background-color:[bg];position:relative;cursor:pointer;",
    "% option": "border:solid [brw] [brd];background-color:[bg];padding:5px;",
    "%:after": "content:\"▼\";position:absolute;top:3px;bottom:3px;right:0;width:35px;pointer-events:none;[rd]  box-sizing:border-box !important;background-color:[bg2];border:[brw] solid [brd];color:#fff;text-align:center;font-size:10px;line-height:30px;text-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6);cursor:pointer !important;border-bottom-left-radius:0;border-top-left-radius:0;"
  }
}
 skins['wysiwyg.viewer.skins.input.ComboBoxInputSkin'] = {
  "react": [
    [
      "select",
      "collection",
      [],
      {}
    ],
    [
      "p",
      "errorMessage",
      [],
      {}
    ]
  ],
  "params": {
    "txt": "TEXT_COLOR",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "bg2": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "txt": "#333",
    "brw": "2px",
    "brd": "#1ab1ab",
    "rd": "5px",
    "bg": "#fff",
    "bg2": "#aaa"
  },
  "css": {
    "%": "position:relative;display:inline-block;padding:0;margin:0;",
    "% select": "color:[txt];border:solid [brw] [brd];padding:5px 40px 5px 5px;[rd]  box-sizing:border-box !important;-webkit-appearance:none !important;-moz-appearance:none !important;background-color:[bg];position:relative;cursor:pointer;",
    "% option": "border:solid [brw] [brd];background-color:[bg];padding:5px;",
    "%:after": "content:\"▼\";position:absolute;top:2px;bottom:2px;right:0;width:35px;pointer-events:none;[rd]  box-sizing:border-box !important;background-color:[bg2];border:[brw] solid [brd];color:#fff;text-align:center;font-size:10px;line-height:30px;text-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6);cursor:pointer !important;border-bottom-left-radius:0;border-top-left-radius:0;",
    "%[data-state~=\"invalid\"] select": "border-color:#d00;color:#d00;",
    "%[data-state~=\"invalid\"]:after": "content:\"▼\";position:absolute;top:2px;bottom:2px;right:0;width:35px;pointer-events:none;[rd]  box-sizing:border-box !important;background-color:[bg2];border:#d00 solid [brd];color:#d00;text-align:center;font-size:10px;line-height:30px;text-shadow:inset 0 1px 2px rgba(0, 0, 0, 0.6);cursor:pointer !important;border-bottom-left-radius:0;border-top-left-radius:0;",
    "%[data-state~=\"invalid\"]:hover + p": "opacity:1;color:#d00;",
    "% p": "[rd]  opacity:0;display:inline-block;position:absolute;left:0;bottom:100%;width:140px;padding:10px;font-size:13px;line-height:16px;background:#fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.6);",
    "% p:after": "content:\"▼\";position:absolute;bottom:-12px;left:10px;color:#fff;text-shadow:0 1px 3px rgba(0, 0, 0, 0.6);[rd]"
  }
}

        return skins;
    }));